<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 sm12 md6>
          <v-card>
            <v-card-title primary-title>
              <div>
                <div class="title">Confeccion de productos</div>
              </div>
            </v-card-title>
            <v-card-text>
              <v-layout row wrap v-if="products.length > 0">
                <template v-for="(p, i) in products">
                  <v-flex xs6 :key="i">
                    <v-layout class="ma-0" row wrap>
                      <v-flex xs4>
                        <v-img :src="p.photo_small" aspect-ratio="1"></v-img>
                      </v-flex>
                      <v-flex xs8>
                        <h2 class="subheading" v-html="`${p.quantity} x ${p.name}`" />
                        <h3 class="body-2 mt-2">
                          Pedido
                          <router-link :to="{ name: 'orderIndex', params: { id: p.id }}">
                            {{ p.id }}
                          </router-link>
                        </h3>
                        <p class="caption" v-html="p.schedule.horario" />
                        <template v-if="!p.confeccion.status && p.confeccion.id === undefined">
                          <v-btn @click="openDetail(p)" small class="ma-0" color="info">Detalle</v-btn>
                          <v-btn @click="openDesigner(p, 0)" small class="ma-0 ml-1" color="error">Asignar</v-btn>
                        </template>
                        <template v-else-if="p.confeccion.status === 0">
                          <v-chip label small class="ma-0 mb-2" color="warning" text-color="white" >
                            {{ p.confeccion.name }} - <time-go :number="p.confeccion.seconds"></time-go>
                          </v-chip>
                          <v-btn @click="openDetail(p)" small class="ma-0" color="info">Detalle</v-btn>
                          <v-btn :loading="loadingDialog" @click="finishDesigner(p, 1)" small class="ma-0" color="success">Finalizar</v-btn>
                        </template>
                        <template v-else-if="p.confeccion.status === 1">
                          <v-chip label small class="ma-0 mb-2" color="success" text-color="white" >
                            Finalizado
                          </v-chip>
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-layout>
              <v-layout row wrap v-else>
                <v-flex xs12>
                  <v-alert
                    :value="true"
                    color="accent"
                    icon="info"
                    outline
                  >No hay productos para hoy</v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md6>
          <v-card class="mb-3">
            <v-card-title primary-title>
              <div>
                <div class="title">Estado de tienda</div>
              </div>
            </v-card-title>
            <v-card-text>
              <h2 class="display-1 grey--text" v-if="activos.length > 0">Abierta y recibiendo pedidos para entrega hoy</h2>
              <h2 class="display-1 grey--text" v-if="activos.length === 0">Cerrada por el dia de hoy</h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialog.statusStore = !dialog.statusStore" dark dense depressed color="warning" v-if="activos.length > 0">Cerrar Tienda</v-btn>
              <v-btn @click="dialog.statusStore = !dialog.statusStore" dark dense depressed color="success" v-if="activos.length === 0">Abrir Tienda</v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="mb-3">
            <v-card-text>
              <fusioncharts
                ref="fusion"
                :type="type"
                :width="width"
                :height="height"
                :dataformat="dataFormat"
                :dataSource="dataSource"
                @disposed="disposed"
              ></fusioncharts>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title primary-title>
              <div>
                <div class="title">Pedidos por liquidar</div>
              </div>
            </v-card-title>
            <v-card-text>
              <v-layout class="ma-0" row wrap justify-end>
                <v-flex xs4>
                  <div>
                    <h2 class="display-1 grey--text">{{ montoTotal | toPrice }}</h2>
                    <h3 class="subheading">Ventas {{ this.$moment().format('MMMM') }}</h3>
                    <p class="caption mb-0">{{rows.length}} pedidos</p>
                  </div>
                </v-flex>
                <v-flex xs4>
                  <div>
                    <h2 class="display-1 grey--text">-{{ descuentoTotal + comision + comisionIVA | toPrice }}</h2>
                    <h3 class="subheading">Insumos {{ this.$moment().format('MMMM') }}</h3>
                  </div>
                </v-flex>
                <v-flex xs4>
                  <div>
                    <h2 class="display-1">{{ pagarTotal | toPrice }}</h2>
                    <h3 class="subheading">Total a pagar</h3>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog.statusStore" :fullscreen="$mq !== 'lg'" max-width="800px" scrollable>
      <v-card>
        <title-dialog title="Estado del dia de hoy">
          <template slot="icons">
            <v-btn flat icon @click.native="dialog.statusStore = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </title-dialog>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;" v-if="avaibility.length > 0">
          <v-layout wrap class="body-2">
            <v-flex xs6 class="pb-2 pt-2">
              <v-checkbox v-model="all" hide-details class="ma-0">
                <template slot="label"><span class="subheading font-weight-bold">Comuna</span> </template>
              </v-checkbox>
            </v-flex>
            <v-flex xs3 class="subheading font-weight-bold pb-2 pt-2">
              Hora Cierre
            </v-flex>
            <v-flex xs3 class="subheading font-weight-bold pb-2 pt-2">
              Estado
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <template v-for="(state, index) in avaibility">
            <v-layout wrap justify-center align-center :key="index">
              <v-flex xs6>
                <v-checkbox v-model="selectedCommunesClose" :value="state" hide-details class="ma-0">
                  <template slot="label">
                    {{ state.name }} <span class="caption ml-1"> ({{ state.value | toPrice }})</span>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex xs3>
                <span v-if="!state.shipping"><v-chip label color="error" text-color="white" class="ma-0" small> {{ state.timeFormat }} hrs </v-chip></span>
                <span v-else>
                  <v-chip label color="warning" text-color="black" class="ma-0" small>En {{ $moment.unix(state.time).toNow(true) }} se baja</v-chip>
                </span>
              </v-flex>
              <v-flex xs3>
                <v-chip color="success" text-color="white" v-if="state.shipping" class="my-2">
                  <v-avatar>
                    <v-icon>check_circle</v-icon>
                  </v-avatar>
                  Abierto
                </v-chip>
                <v-chip color="error" text-color="white" v-if="!state.shipping && !state.shipping_fecha" class="my-2">
                  <v-icon left>pause_circle_filled</v-icon>
                  Cerrado Horario
                </v-chip>
                <v-chip color="warning" text-color="white" v-if="!state.shipping && state.shipping_fecha" class="my-2">
                  <v-icon left>pause_circle_filled</v-icon>
                  Cerrado Manual
                </v-chip>
                <v-chip color="warning" text-color="white" v-if="state.shipping === null" class="my-2">
                  <v-icon left>remove_circle</v-icon>
                  Limitado
                </v-chip>
              </v-flex>
            </v-layout>
            <v-divider :key="`d-${index}`" v-if="avaibility.length !== (index+1)"></v-divider>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="selectedCommunesClose.length === 0" color="error" @click.native="updateScheduleComunas('close')" depressed>Cerrar</v-btn>
          <v-btn :disabled="selectedCommunesClose.length === 0" color="success" @click.native="updateScheduleComunas('open')" depressed>Abrir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.updateDesigner" max-width="500px" persistent>
      <v-card>
        <title-dialog :title="`Asignar diseñador`"></title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select v-model="confeccionID" item-text="name" item-value="id" :items="disenadores" />
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.stop="dialog.updateDesigner = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
            flat
            :loading="loadingDialog"
            :disabled="loadingDialog"
            @click.native="updateDesigner"> Asignar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.detail" max-width="800px">
      <v-card v-if="productActive">
        <title-dialog :title="productActive.name"></title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <template v-for="(img, i) in productActive.imgs">
              <v-flex xs3 :key="i">
                <img :src="img.img" style="max-width:100%;" alt="img.img" />
              </v-flex>
            </template>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <h2 class="title">Receta</h2>
              <ul class="">
                <template v-for="(r, i) in productActive.receta">
                  <li :key="i">{{ r.quantity }} x {{ r.name }}</li>
                </template>
              </ul>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import FusionCharts from 'fusioncharts';
import TitleDialog from '../useful/titleDialog.vue';
import TimeGo from '../useful/timeGo.vue';
import { GET_API, GET_STATUS_STORE } from '../../config';

// eslint-disable-next-line func-names
let handler = function (e) {
  const general = this.$store.getters.getGeneral;
  general.date = this.$moment(this.$moment(e.data.categoryLabel, 'DD-MM-YYYY')).format('YYYY-MM-DD');
  this.$store.dispatch('setGeneral', general);
  this.$router.push({ name: 'pedidos' });
};

export default {
  name: 'Inicio Asociado',
  components: { TitleDialog, TimeGo },
  metaInfo: {
    title: 'Inicio',
  },
  computed: {
    disenadores() {
      return this.$store.getters.getDisenadores;
    },
    user() {
      return this.$store.getters.getUser;
    },
    general() {
      return this.$store.getters.getGeneral;
    },
    pagarTotal() {
      return (
        this.montoTotal
        - (this.descuentoTotal + this.comision + this.comisionIVA)
      );
    },
    montoTotal() {
      return this._.sumBy(this.rows, 'valor');
    },
    comisionIVA() {
      return parseInt(((this.comision * 19) / 100).toFixed(0), 10);
    },
    comision() {
      return parseInt(((this.montoTotal * 10) / 100).toFixed(0), 10);
    },
    descuentoTotal() {
      return this._.sumBy(this.discounts, 'value');
    },
    inactivos() {
      return this._.filter(this.avaibility, ['shipping', false]);
    },
    activos() {
      return this._.filter(this.avaibility, ['shipping', true]);
    },
  },
  watch: {
    all(e) {
      if (e) {
        this.listStateStatus.forEach((element) => {
          this.selectedCommunesClose.push(element);
        });
      } else {
        this.selectedCommunesClose = [];
      }
    },
  },
  data() {
    return {
      products: [],
      rows: [],
      facturacion: [],
      discounts: [],
      semana: [],
      avaibility: [],
      selectedCommunesClose: [],
      type: 'column2d',
      renderAt: 'chart-container',
      width: '100%',
      height: '305',
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: 'Pedidos de esta semana',
          subcaption: 'Pagados, Despacho o Entregados',
          xaxisname: '',
          yaxisname: 'Pedidos',
          numbersuffix: '',
          theme: 'gammel',
        },
        data: [],
      },
      dialog: {
        statusStore: false,
        updateDesigner: false,
        detail: false,
      },
      all: false,
      loadingDialog: false,
      confeccionID: 0,
      productActive: {},
      typeActive: 0,
    };
  },
  methods: {
    async searchStatesSchedule() {
      try {
        const res = await this.$http.get(GET_STATUS_STORE, {
          params: {
            all: false,
          },
        });
        this.avaibility = res.data;
        this.selectedCommunesClose = [];
        this.dialog.statusStore = false;
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' });
      }
    },
    async getData() {
      const response = await this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getDashboardNew',
          date: this.general.date,
        },
      });
      this.products = response.data.json.orders;
      this.dataSource.data = response.data.json.semana;
      this.rows = response.data.json.liquidacion.orders;
      this.discounts = response.data.json.liquidacion.discount;
      this.facturacion = response.data.json.liquidacion.facturacion;
      this.avaibility = response.data.json.status;
    },
    async updateScheduleComunas(type) {
      if (this.selectedCommunesClose.length > 0) {
        try {
          await this.$http.post(GET_STATUS_STORE, {
            type,
            communes: this.selectedCommunesClose,
          });
          this.searchStatesSchedule();
        } catch (error) {
          this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' });
        }
      }
    },
    disposed() {
      FusionCharts.removeEventListener('dataPlotClick', handler);
    },
    openDesigner(p, t) {
      this.dialog.updateDesigner = true;
      this.productActive = p;
      this.typeActive = t;
    },
    finishDesigner(p, t) {
      this.productActive = p;
      this.typeActive = t;
      this.updateDesigner();
    },
    updateDesigner() {
      this.loadingDialog = true;
      const disenadorID = (!this.typeActive) ? this.confeccionID : this.productActive.confeccion.id;
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'updateDesignerOrder',
          type: (this.typeActive),
          detailID: this.productActive.id_detalle,
          orderID: this.productActive.id,
          productID: this.productActive.id_producto,
          disenadorID,
        },
      }).then(() => {
        this.getData();
        this.loadingDialog = false;
      });
    },
    openDetail(p) {
      this.dialog.detail = true;
      this.productActive = p;
    },
  },
  mounted() {
    this.getData();
    handler = handler.bind(this);
    FusionCharts.addEventListener('dataPlotClick', handler);
  },
};
</script>

<style></style>
